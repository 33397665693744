.app-list-calendar {
	height: 100%;

	.list-calendar {
		height: 100%;
		display: flex;
		flex-direction: column;

		.app-button {
			width: auto;
			margin: 0 0 0 10px;
			padding: 10px 15px;
			flex-direction: row-reverse;

			@include max-width($scr-md) {
				margin: 10px 0;
			}

			.app-icon {
				width: 20px;
				height: 20px;
				margin-left: 5px;
			}
		}

		&__calendar {
			padding: 0 0 35px;
		}

		.leaves-calendar {
			margin: 0 35px;

			@include max-width($scr-sm) {
				margin: 0 15px;
			}

			&__header {
				width: 100%;
				padding: 10px 0 25px;
				display: flex;
				align-items: center;
				justify-content: center;

				@include max-width($scr-lg) {
					flex-wrap: wrap;
					justify-content: space-between;
				}
			}

			&__current {
				margin: 0 10px 0 -40px;
				color: $c-primary;
				font-weight: $text-weight-normal;

				@include max-width($scr-md) {
					margin: 0 10px;
				}
			}

			&__button {
				padding: 10px 25px;
				border-radius: 100px;
				color: $c-primary;
				font-size: $text-size-s;
				font-weight: $text-weight-normal;
				background-color: $c-white;
				border: 1px solid $c-light-grey;
				pointer-events: all;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				@include max-width($scr-sm) {
					margin: 0 0 10px;
				}

				.app-chevron-icon {
					width: 15px;
					height: 15px;
					pointer-events: none;
				}

				&--prev {
					position: relative;
					padding: 10px;
					border-right: 0;
					border-radius: 8px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;

					&:after {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						right: -0.5px;
						display: block;
						width: 1px;
						background-color: $c-light-grey;
					}

					.app-chevron-icon {
						transform: rotate(90deg);
					}
				}

				&--next {
					padding: 10px;
					border-left: 0;
					border-radius: 8px;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;

					.app-chevron-icon {
						transform: rotate(-90deg);
					}
				}
			}

			&__navigation {
				margin: 0 10px;
				display: flex;
				align-items: center;

				@include min-width($scr-md) {
					margin: 0 auto;
				}
			}

			&__body {
				border-radius: 10px;
				background-color: $c-white;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			}

			&__list {
				padding: 0;
				margin: 0;
				display: flex;
				flex-direction: column;
			}

			&__item {
				padding: 10px 15px;
				list-style-type: none;
				display: flex;

				&:nth-child(even) {
					background-color: #f8f8f8;
				}

				@include max-width($scr-md) {
					flex-direction: column;
				}
			}

			&__dates {
				flex: 1;
				display: flex;
			}

			&__text {
				margin: 0;
				padding: 15px;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				display: flex;
				flex-direction: column;
				flex: 1;

				&--today {
					color: $c-primary;
					font-weight: $text-weight-semibold;
				}
			}

			&__holiday {
				margin: 5px 0 0;
				color: $c-teal;
				font-size: $text-size-xxs;
				font-weight: $text-weight-normal;
			}

			&__states {
				margin: 5px 0 0;
				color: $c-light-grey;
				font-size: $text-size-xxs;
			}

			&__date {
				width: 30px;
				margin: 0;
				padding: 15px 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				font-weight: $text-weight-semibold;
				display: flex;
				justify-content: center;

				&--today {
					position: relative;
					color: $c-white;
					z-index: 10;

					&:after {
						content: "";
						position: absolute;
						top: 7px;
						display: block;
						width: 30px;
						height: 30px;
						border-radius: 100px;
						background-color: $c-primary;
						z-index: -1;
					}
				}
			}

			&__wrapper {
				flex: 4;
			}

			&__candidates {
				display: flex;

				@include max-width($scr-md) {
					flex-direction: column;
				}
			}

			&__day {
				min-width: 200px;
				margin: 0;
				padding: 15px 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				flex: 1;

				&--holiday {
					color: $c-primary;
					font-weight: $text-weight-semibold;
				}
			}

			&__applier {
				display: flex;
				align-items: center;
			}

			&__label {
				margin: 0;
				color: $c-black;
				font-size: $text-size-xs;
			}

			&__candidate {
				width: 100%;
				margin: 0;
				padding: 9.5px 15px;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				flex: 3;
				gap: 10px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				@include max-width($scr-md) {
					padding: 9.5px 0;
					width: 100%;
				}
			}

			&__profile {
				width: 25px;
				height: 25px;
				margin: 0 5px;
				border-radius: 25px;
				background-color: $c-light-grey;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}
