.page-leaves-history {
	height: 100%;

	.leaves-history {
		height: 100%;
		display: flex;
		flex-direction: column;

		.app-table {
			.table {
				&__attachment {
					padding: 0 15px 0 0;
					text-align: center;
					color: $c-dark-grey;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}

				&__file {
					cursor: pointer;
				}

				&__sort {
					border: 0;
					padding: 0;
					opacity: 0.2;
					background-color: transparent;
					transition: $transition;
					cursor: pointer;

					&--active {
						opacity: 1;
					}

					&:hover {
						opacity: 1;
					}

					img {
						width: 12.5px;
						height: 12.5px;
						margin: 0 5px;
						vertical-align: middle;
						pointer-events: none;
					}
				}

				&__exclamation {
					width: 35px;
					height: 35px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
