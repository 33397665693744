.page-claim-summary {
	height: 100%;

	.claim-summary {
		height: 100%;
		display: flex;
		flex-direction: column;

		.app-table {
			.table {
				&__th {
					&:not(:first-child) {
						text-align: center;
					}
				}
			}
		}
	}
}
