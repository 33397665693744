.page-leaves-summary {
	height: 100%;

	.leaves-summary {
		height: 100%;
		display: flex;
		flex-direction: column;

		.app-table {
			.table {
				&__th,
				&__td {
					&:not(:first-child) {
						text-align: center;
					}
				}
			}
		}
	}
}
