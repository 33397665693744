.app-grid-calendar {
	height: 100%;

	.grid-calendar {
		height: 100%;
		display: flex;
		flex-direction: column;

		.app-button {
			width: auto;
			margin: 0 0 0 10px;
			padding: 10px 15px;
			flex-direction: row-reverse;

			@include max-width($scr-md) {
				margin: 10px 0;
			}

			.app-icon {
				width: 20px;
				height: 20px;
				margin-left: 5px;
			}
		}

		&__calendar {
			padding: 0 0 35px;
		}

		.leaves-calendar {
			margin: 0 35px;

			@include max-width($scr-sm) {
				margin: 0 15px;
			}

			&__header {
				width: 100%;
				padding: 10px 0 25px;
				display: flex;
				align-items: center;
				justify-content: center;

				@include max-width($scr-lg) {
					flex-wrap: wrap;
					justify-content: space-between;
				}
			}

			&__current {
				margin: 0 10px 0 -40px;
				color: $c-primary;
				font-weight: $text-weight-normal;

				@include max-width($scr-md) {
					margin: 0 10px;
				}
			}

			&__button {
				padding: 10px 25px;
				border-radius: 100px;
				color: $c-primary;
				font-size: $text-size-s;
				font-weight: $text-weight-normal;
				background-color: $c-white;
				border: 1px solid $c-light-grey;
				pointer-events: all;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				@include max-width($scr-sm) {
					margin: 0 0 10px;
				}

				.app-chevron-icon {
					width: 15px;
					height: 15px;
					pointer-events: none;
				}

				&--prev {
					position: relative;
					padding: 10px;
					border-right: 0;
					border-radius: 8px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;

					&:after {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						right: -0.5px;
						display: block;
						width: 1px;
						background-color: $c-light-grey;
					}

					.app-chevron-icon {
						transform: rotate(90deg);
					}
				}

				&--next {
					padding: 10px;
					border-left: 0;
					border-radius: 8px;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;

					.app-chevron-icon {
						transform: rotate(-90deg);
					}
				}
			}

			&__navigation {
				margin: 0 10px;
				display: flex;
				align-items: center;

				@include min-width($scr-md) {
					margin: 0 auto;
				}
			}

			&__body {
				display: flex;
				flex-direction: row;

				@include max-width($scr-md) {
					flex-direction: column;
				}
			}

			&__weekdays {
				padding: 0;
				margin: 0;
				background-color: #d8e6fa;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				border-top: 1px solid #e6e6e6;
				border-left: 1px solid #e6e6e6;
				border-right: 1px solid #e6e6e6;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__weekday-item {
				width: 14.28%;
				padding: 10px 0;
				list-style-type: none;
			}

			&__weekday {
				color: $c-primary;
				text-align: center;
				font-weight: $text-weight-semibold;
			}

			&__list {
				padding: 0;
				margin: 0;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				background-color: $c-white;
				border: 1px solid #e6e6e6;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			}

			&__item {
				width: 14.28%;
				aspect-ratio: 1;
				padding: 10px;
				list-style-type: none;
				border-right: 1px solid #e6e6e6;
				border-bottom: 1px solid #e6e6e6;
				overflow: hidden;
				cursor: pointer;
				display: flex;
				flex-direction: column;

				&:nth-child(7n) {
					border-right: 0;
				}
			}

			&__dates {
				flex: 1;
				display: flex;
				flex-direction: column;
			}

			&__text {
				margin: 0;
				padding: 5px;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				border-radius: 5px;
				display: flex;
				flex-direction: column;

				&--today {
					color: $c-primary;
					font-weight: $text-weight-semibold;
				}
			}

			&__holiday {
				margin: 5px 0 0;
				color: $c-green;
				font-size: $text-size-xxs;
				font-weight: $text-weight-normal;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			&__holiday-event {
				margin: 0 0 5px;
				background-color: rgba(58, 218, 178, 0.2);
			}

			&__date {
				margin: 0;
				width: 30px;
				height: 30px;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				font-weight: $text-weight-semibold;
				display: flex;
				align-items: center;
				justify-content: center;
				align-self: flex-end;

				&--today,
				&--leave {
					position: relative;
					color: $c-white;
					z-index: 10;

					&:after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						display: block;
						width: 30px;
						height: 30px;
						border-radius: 100px;
						background-color: $c-primary;
						z-index: -1;
					}
				}

				&--leave {
					color: $c-dark-grey;

					&:after {
						background-color: rgba(255, 199, 112, 0.5);
					}
				}
			}

			&__leaves {
				width: 100%;
				max-width: 250px;
				padding: 0 20px;
				margin: 0 20px;
				list-style-type: none;
				border-left: 1px solid #f0f0f0;

				@include max-width($scr-md) {
					max-width: 100%;
					padding: 0;
					margin: 20px 0 0;
					border: 0;
				}
			}

			&__on-leave {
				margin: 0 0 10px;
				display: flex;
				align-items: center;
				flex-direction: row;
			}

			&__profile {
				width: 30px;
				height: 30px;
				border-radius: 30px;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				flex-shrink: 0;
			}

			&__on-leave-name {
				margin: 0 10px;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}

			&__on-leave-type {
				color: $c-light-grey;
				font-size: $text-size-xxs;
			}

			&__on-leave-date {
				margin: 0 0 0 auto;
				color: $c-primary;
				font-size: $text-size-xs;
				font-weight: $text-weight-semibold;
			}
		}
	}
}

.app-leaves-menu {
	//
	.MuiList-root {
		aspect-ratio: 1;
		min-width: 150px;
		min-height: 150px;
		max-width: 250px;
		padding: 0 !important;
	}

	.MuiMenuItem-root {
		padding: 0 !important;

		&:hover {
			background-color: transparent;
		}

		.holiday-event {
			width: 100%;
			padding: 10px;
			display: flex;
			flex-direction: row;

			&:before {
				content: "";
				width: 25px;
				height: 25px;
				margin: 0 5px 0 0;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("../../../../assets/images/pages/leave-management/holiday-icon.svg");
				display: block;
				flex-shrink: 0;
			}

			&__text {
				margin: 0;
				white-space: wrap;
				word-wrap: break-word;
				font-size: $text-size-xs;
				font-weight: $text-weight-normal;
			}

			&__state {
				color: $c-light-grey;
				font-size: $text-size-xxs;
			}
		}
	}
}
