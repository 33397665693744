.page-leaves-pending {
	height: 100%;

	.leaves-pending {
		height: 100%;
		display: flex;
		flex-direction: column;

		.app-table {
			.table {
				&__attachment {
					padding: 0 15px 0 0;
					text-align: center;
					color: $c-dark-grey;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}

				&__file {
					cursor: pointer;
				}

				&__exclamation {
					width: 35px;
					height: 35px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
