.page-profile {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px 15px;
	}

	.profile {
		padding-bottom: 25px;

		&__container {
			margin: 30px 0;
			width: 100%;
			max-width: 1280px;
		}

		&__name {
			margin: 0 0 10px;
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}
	}
}
