.page-login {
	background-color: $c-background;

	.login {
		position: relative;
		min-height: 100vh;

		.app-alert {
			position: fixed;
			margin-top: 15px;
			left: 10%;
			right: 10%;
		}

		.main {
			width: 100%;
			min-height: 100vh;
			display: flex;
			flex-direction: row;
			align-items: stretch;

			@include max-width($scr-sm) {
				flex-direction: column;
			}

			&__content {
				width: 65%;
				background-color: $c-primary;

				&--background {
					width: 35%;
					min-width: 400px;
					min-height: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-end;

					@include max-width($scr-sm) {
						display: none;
					}
				}

				&--form {
					width: 100%;
					min-height: 100vh;
					max-width: 650px;
					padding: 20px;
					background-color: $c-background;
					display: flex;
					justify-content: center;
					flex-direction: column;

					@include min-width($scr-sm) {
						padding: 20px 20px 20px calc(5.5vw + 20px);
					}
				}
			}

			&__logo {
				position: relative;
				width: 90%;
				margin-right: -5vw;
				display: block;
				z-index: 999;

				img {
					width: 100%;
					max-width: 650px;
				}
			}

			&__form {
				margin-top: auto;
				margin-bottom: auto;
			}

			&__title {
				margin: 0;
				color: $c-primary;
				font-weight: $text-weight-semibold;
				font-size: $text-size-xxl;
			}

			&__description {
				color: $c-dark-grey;
				font-size: $text-size-body;
			}

			&__wrapper {
				margin: 35px 0 0;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			&__forgot-password {
				color: $c-blue;
				font-size: $text-size-s;
				text-decoration: none;
				cursor: pointer;
			}

			&__button-container {
				margin: 50px 0 0;
				max-width: 250px;
			}

			&__copyright {
				color: $c-light-grey;
				font-size: $text-size-xs;
			}

			.app-input {
				margin-bottom: 20px;
			}
		}
	}
}
