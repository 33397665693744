.app-personal-info-tab {
	width: 100%;

	.personal-info {
		width: 100%;
		gap: 15px;
		display: flex;
		flex-direction: column;

		&__header {
			margin: 0 0 20px;
			padding: 0 0 15px;
			border-bottom: 1px dashed $c-disabled;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.app-button {
				width: auto;
				padding: 10px 15px;
				display: flex;
				flex-direction: row-reverse;

				.app-icon {
					width: 12.5px;
					height: 12.5px;
				}

				&__label {
					margin-right: 5px;
					font-size: $text-size-xs;
				}
			}
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-weight: $text-weight-semibold;
		}

		&__box {
			flex: 1;
			padding: 25px 15px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

			.app-button {
				&:disabled {
					.app-button__label {
						color: $c-white;
					}
				}
			}
		}

		&__form {
			width: 100%;
			gap: 20px 35px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.app-input,
			.app-mobile-input,
			.app-select-input,
			.app-button-input,
			.app-masking-input,
			.app-calendar-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}
		}

		&__checkbox {
			margin: 20px 0 0;

			.app-checkbox {
				&__label {
					font-weight: $text-weight-normal;
				}
			}
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
