.page-app {
	background-color: $c-primary;

	.app {
		position: relative;
		min-height: 100vh;

		.main {
			width: 100%;
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include max-width($scr-sm) {
				flex-direction: column;
			}

			&__content {
				width: 35%;
				min-width: 320px;
				min-height: 100%;
				margin: 0 auto;
			}

			&__logo {
				position: relative;
				width: 100%;
				padding: 10px;
				max-width: 650px;
				display: block;
				z-index: 999;
			}

			&__copyright {
				position: absolute;
				bottom: 15px;
				left: 0;
				right: 0;
				color: $c-white;
				text-align: center;
				font-size: $text-size-xs;
			}
		}
	}
}
